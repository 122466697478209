var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hideInform)?_c('v-col',{staticClass:"p-0 d-block-inline",attrs:{"cols":_vm.colSize}},[(_vm.label.type == _vm.$fieldTypes.SUBTITLE)?_c('div',{staticClass:"pb-2"},[_vm._v(_vm._s(_vm.label.name))]):_c('v-form',{ref:"form",class:{ 'my-1': _vm.hideDetails },attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[(
        !_vm.isNumber(_vm.label.type) &&
        _vm.label.type != _vm.$fieldTypes.AVATAR &&
        !_vm.hideDetails
      )?_c('v-sheet',{staticClass:"v-label-input pl-2 d-flex align-center",class:{
        'mt-n1': !!_vm.label.ajuda || !_vm.exibirCampo,
        'pb-1': !!_vm.label.ajuda,
        'pb-2': !_vm.label.ajuda,
        'mb-5': !_vm.exibirCampo,
        'pt-2': !_vm.exibirCampo,
        'font-weight-medium': _vm.label.labelBold,
      },style:({ 'min-height': _vm.label.ajuda ? '23px' : '19px' }),attrs:{"rounded":"","outlined":!_vm.exibirCampo,"color":"lighten-3"}},[_c('span',[_vm._v(_vm._s(_vm.label.nameInForm || _vm.label.name))]),(_vm.label.ajuda)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.exibirAjuda = !_vm.exibirAjuda}}},[(_vm.exibirAjuda)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help-circle")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help-circle-outline")])],1):_vm._e(),(_vm.collapsible)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.exibirCampo = !_vm.exibirCampo}}},[(_vm.exibirCampo)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-double-up")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-double-down")])],1):_vm._e(),(!_vm.isUndefined(_vm.check))?_c('v-btn',{staticClass:"ml-auto",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.canCheck ? _vm.$emit('update:check', _vm.check != 1 ? 1 : 0) : 0;
            _vm.canCheck ? _vm.$emit('changeCheck'): 0;}}},[(_vm.check === 1)?_c('v-icon',{attrs:{"color":"#25935F"}},[_vm._v("mdi-check-circle")]):_c('v-icon',[_vm._v("mdi-check-circle-outline")])],1):_vm._e()],1):_vm._e(),_c('v-expand-transition',[(_vm.exibirAjuda)?_c('div',[_c('div',{staticClass:"v-help-text mx-3 mb-2"},[_vm._v(_vm._s(_vm.label.ajuda))])]):_vm._e()]),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.exibirCampo),expression:"exibirCampo"}]},[_c('v-sheet',{class:{ 'px-2': _vm.label.outline, 'mb-4': _vm.label.outline },attrs:{"outlined":_vm.label.outline,"rounded":""}},[(_vm.label.type == _vm.$fieldTypes.AUTOCOMPLETE)?_c('v-autocomplete',{ref:"autocompleteRef",attrs:{"items":_vm.listItems,"item-value":_vm.label.rows.id,"item-text":_vm.label.rows.nome,"value":_vm.value,"menu-props":{maxWidth: ("" + _vm.comboWidth)},"dense":"","outlined":"","readonly":_vm.editable === false || _vm.label.editable === false,"rules":_vm.rulesComputed,"clearable":_vm.canClearable && _vm.editable && _vm.label.editable !== false,"auto-select-first":"","hide-details":_vm.hideDetails,"placeholder":_vm.label.placeholder},on:{"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [(_vm.label.multiple)?_c('v-chip',{attrs:{"color":"disabled"}},[_c('span',[_vm._v(_vm._s(item[_vm.label.rows.nome]))])]):_c('span',[_vm._v(_vm._s(item[_vm.label.rows.nome]))])]}},{key:"item",fn:function(ref){
              var item = ref.item;
              var attrs = ref.attrs;
return [(_vm.label.multiple)?_c('v-checkbox',{key:item[_vm.label.rows.id],attrs:{"input-value":attrs.inputValue}}):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item[_vm.label.rows.nome]))])]}}],null,true)},[_c('span',{style:({
                    'text-overflow': item[_vm.label.rows.nome].length > 33 ? 'ellipsis' : 'unset'
                  })},[_vm._v(" "+_vm._s(item[_vm.label.rows.nome])+" ")])])]}}],null,false,2772563274)}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.AUTOCOMPLETE_MULTIPLE)?_c('v-autocomplete',{ref:"autocompleteRef",attrs:{"items":_vm.listItems,"item-value":_vm.label.rows.id,"item-text":_vm.label.rows.nome,"value":_vm.value,"menu-props":{maxWidth: ("" + _vm.comboWidth)},"dense":"","outlined":"","readonly":_vm.editable === false || _vm.label.editable === false,"rules":_vm.rulesComputed,"clearable":_vm.canClearable && _vm.editable && _vm.label.editable !== false,"auto-select-first":"","deletable-chips":"","multiple":"","small-chips":"","hide-details":_vm.hideDetails,"placeholder":_vm.label.placeholder},on:{"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"color":"disabled"}},[_c('span',[_vm._v(_vm._s(item[_vm.label.rows.nome]))])])]}},{key:"item",fn:function(ref){
              var item = ref.item;
              var attrs = ref.attrs;
return [_c('v-checkbox',{key:item[_vm.label.rows.id],attrs:{"input-value":attrs.inputValue}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item[_vm.label.rows.nome]))])]}}],null,true)},[_c('span',{style:({
                    'text-overflow': item[_vm.label.rows.nome].length > 33 ? 'ellipsis' : 'unset'
                  })},[_vm._v(" "+_vm._s(item[_vm.label.rows.nome])+" ")])])]}}],null,false,1876325098)}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.TEXT)?_c('v-text-field',{class:{
              'center-input': _vm.label.align == 0,
              'right-input': _vm.label.align == 1,
            },attrs:{"readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","counter":_vm.label.counter,"rules":_vm.rulesComputed,"value":_vm.value,"hide-details":_vm.hideDetails,"placeholder":_vm.label.placeholder},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"blur":function($event){return _vm.$emit('blur', $event)},"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);},"keypress":function($event){return _vm.$emit('keypress', $event)},"paste":function($event){return _vm.$emit('paste', $event)}}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.DATETIME)?_c('v-text-field',{class:{
              'center-input': _vm.label.align == 0,
              'right-input': _vm.label.align == 1,
            },attrs:{"readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"value":_vm._f("toDateTime")(_vm.value),"hide-details":_vm.hideDetails},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);}}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.TEXTAREA)?_c('v-textarea',{class:{
              'center-input': _vm.label.align == 0,
              'right-input': _vm.label.align == 1,
            },attrs:{"readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"value":_vm.value,"hide-details":_vm.hideDetails,"counter":_vm.label.counter,"placeholder":_vm.label.placeholder},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);},"keypress":function($event){return _vm.$emit('keypress', $event)},"paste":function($event){return _vm.$emit('paste', $event)}}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.PASSWORD)?_c('v-text-field',{class:{
              'center-input': _vm.label.align == 0,
              'right-input': _vm.label.align == 1,
            },attrs:{"type":"password","readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"value":_vm.value,"hide-details":_vm.hideDetails},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);}}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.NUMBER)?_c('v-text-field',{attrs:{"type":"number","readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"value":_vm.value,"hide-details":_vm.hideDetails},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);}}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.DOUBLE)?_c('v-text-field',{attrs:{"type":"number","readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"value":parseFloat(_vm.value | _vm.toDouble),"hide-details":_vm.hideDetails},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);}}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.MONEY)?_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"hide-details":_vm.hideDetails},on:{"click":function($event){$event.stopPropagation();return _vm.nop()}},model:{value:(_vm.moneyValue),callback:function ($$v) {_vm.moneyValue=$$v},expression:"moneyValue"}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.MONEY2)?_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money2),expression:"money2"}],attrs:{"readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"hide-details":_vm.hideDetails},on:{"click":function($event){$event.stopPropagation();return _vm.nop()}},model:{value:(_vm.money2Value),callback:function ($$v) {_vm.money2Value=$$v},expression:"money2Value"}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.CPF)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.cpfMask),expression:"cpfMask"}],class:{
              'center-input': _vm.label.align == 0,
              'right-input': _vm.label.align == 1,
            },attrs:{"readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"type":"tel","value":_vm.value,"hide-details":_vm.hideDetails},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"change":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', _vm.eventCpf($event));}}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.CNPJ)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.cnpjMask),expression:"cnpjMask"}],class:{
              'center-input': _vm.label.align == 0,
              'right-input': _vm.label.align == 1,
            },attrs:{"readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"type":"tel","value":_vm.value,"hide-details":_vm.hideDetails},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"change":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', _vm.eventCpf($event));}}}):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":250,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('div',{staticStyle:{"width":"0px","padding":"0px","margin":"0px","height":"0px"}})]}}],null,false,2467884283),model:{value:(_vm.commentMenu),callback:function ($$v) {_vm.commentMenu=$$v},expression:"commentMenu"}},[_c('v-card',[_c('div',{staticClass:"pt-2 mx-2"},[_vm._v(_vm._s(_vm.label.commentForFieldLabel || "Comentário"))]),_c('v-textarea',{staticClass:"pt-2 mx-2",attrs:{"outlined":"","dense":"","persistent":"","value":_vm.commentForField,"hide-details":true},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('update:commentForField', $event);
                  _vm.$emit('mutate', $event);}}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.commentMenu = false;}}},[_vm._v("OK")])],1)],1)],1),(_vm.label.type == _vm.$fieldTypes.TIME)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.timeMask),expression:"timeMask"}],class:{
              'center-input': _vm.label.align == 0,
              'right-input': _vm.label.align == 1,
            },attrs:{"readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"type":"tel","value":_vm._f("toTime")(_vm.value),"hide-details":_vm.hideDetails},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"change":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', _vm.eventTime($event));}},scopedSlots:_vm._u([(_vm.value)?{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.commentForField},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();_vm.commentMenu = true}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.commentForField ? 'mdi-comment-text' : 'mdi-comment-outline')+" ")])]}}],null,false,1353517741)},[_c('span',[_vm._v(_vm._s(_vm.commentForField))])])]},proxy:true}:null],null,true)}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.BIGTIME)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.bigTimeMask),expression:"bigTimeMask"}],class:{
              'center-input': _vm.label.align == 0,
              'right-input': _vm.label.align == 1,
            },attrs:{"readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"type":"tel","value":_vm._f("toTime")(_vm.value),"hide-details":_vm.hideDetails},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"change":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', _vm.eventTime($event));}}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.TELEPHONE)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.telephoneMask),expression:"telephoneMask"}],class:{
              'center-input': _vm.label.align == 0,
              'right-input': _vm.label.align == 1,
            },attrs:{"readonly":_vm.editable === false || _vm.label.editable === false,"outlined":"","dense":"","rules":_vm.rulesComputed,"type":"tel","value":_vm.value,"hide-details":_vm.hideDetails},on:{"click":function($event){$event.stopPropagation();return _vm.nop()},"change":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', _vm.eventTelephone($event));}}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.DATE)?_c('v-menu',{attrs:{"close-on-click":true,"close-on-content-click":false,"transition":"scroll-y-transition","offset-y":"","right":"","max-width":"290px","min-width":"290px","disabled":_vm.editable === false || _vm.label.editable === false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.dateMask),expression:"dateMask"}],class:{
                  'center-input': _vm.label.align == 0,
                  'right-input': _vm.label.align == 1,
                },attrs:{"value":_vm._f("toDate")(_vm.value),"rules":_vm.rulesComputed,"clearable":_vm.canClearable && _vm.editable && _vm.label.editable !== false,"hide-details":_vm.hideDetails,"role":"input","outlined":"","dense":"","append-icon":(_vm.editable === false || _vm.label.editable === false) ? null : 'mdi-calendar',"readonly":_vm.editable === false || _vm.label.editable === false},on:{"blur":function($event){return _vm.dateFieldBlur($event)},"click:append":function($event){$event.stopPropagation();return on.click($event)},"click:clear":function($event){_vm.$emit('mutate', null);
                  _vm.$emit('input', null);}}},'v-text-field',attrs,false))]}}],null,false,3607804047),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.value || _vm.defaultDatepickerValue,"no-title":""},on:{"click:date":function($event){_vm.dateMenu = false;},"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('mutate', $event);
                _vm.$emit('input', $event);}}})],1):_vm._e(),(_vm.label.type == _vm.$fieldTypes.MONTH)?_c('v-menu',{attrs:{"close-on-click":true,"close-on-content-click":false,"transition":"scroll-y-transition","offset-y":"","right":"","max-width":"290px","min-width":"290px","disabled":_vm.editable === false || _vm.label.editable === false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:{
                  'center-input': _vm.label.align == 0,
                  'right-input': _vm.label.align == 1,
                },attrs:{"value":_vm._f("toMonth")(_vm.value),"outlined":"","rules":_vm.rulesComputed,"dense":"","append-icon":(_vm.editable || _vm.label.editable) ? 'mdi-calendar' : null,"readonly":!(_vm.editable || _vm.label.editable),"clearable":_vm.canClearable && _vm.editable && _vm.label.editable !== false,"hide-details":_vm.hideDetails},on:{"click:append":function($event){$event.stopPropagation();_vm.editable !== false &&
                  _vm.label.editable !== false &&
                  on.click($event)},"click:clear":function($event){_vm.$emit('mutate', null);
                  _vm.$emit('input', null);}}},'v-text-field',attrs,false),on))]}}],null,false,1950636958),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.value || _vm.defaultDatepickerValue,"no-title":"","type":"month"},on:{"change":function($event){return _vm.$emit('changed', $event)},"click:month":function($event){_vm.dateMenu = false;},"input":function($event){_vm.$emit('mutate', $event);
                _vm.$emit('input', $event);}}})],1):_vm._e(),(_vm.label.type == _vm.$fieldTypes.SELECT)?_c('v-select',{attrs:{"items":_vm.listItems,"item-value":_vm.label.rows.id,"item-text":_vm.label.rows.nome,"value":_vm.value,"dense":"","outlined":"","readonly":_vm.editable === false || _vm.label.editable === false,"rules":_vm.rulesComputed,"hide-details":_vm.hideDetails,"clearable":_vm.canClearable && _vm.editable && _vm.label.editable !== false},on:{"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);}}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.COMBOBOX)?_c('v-combobox',{ref:"autocompleteRef",attrs:{"items":_vm.listItems,"value":_vm.value,"dense":"","menu-props":{maxWidth:  ("" + _vm.comboWidth)},"outlined":"","placeholder":_vm.label.placeholder,"readonly":_vm.editable === false || _vm.label.editable === false,"rules":_vm.rulesComputed,"hide-details":_vm.hideDetails},on:{"change":function($event){return _vm.$emit('changed', $event)},"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [(_vm.label.multiple)?_c('v-chip',{attrs:{"color":"disabled"}},[_c('span',[_vm._v(_vm._s(item))])]):_c('span',[_vm._v(_vm._s(item))])]}},{key:"item",fn:function(ref){
              var item = ref.item;
              var attrs = ref.attrs;
return [(_vm.label.multiple)?_c('v-checkbox',{key:_vm.label.key,attrs:{"input-value":attrs.inputValue}}):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item))])]}}],null,true)},[_c('span',{style:({
                    'text-overflow': item.length > 33 ? 'ellipsis' : 'unset'
                  })},[_vm._v(" "+_vm._s(item)+" ")])])]}}],null,false,1921385060)}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.RADIO)?_c('v-radio-group',{attrs:{"value":_vm.value,"row":!_vm.label.column,"dense":"","readonly":_vm.editable === false || _vm.label.editable === false},on:{"change":_vm.changeValue}},_vm._l((_vm.listItems),function(item,index){return _c('v-radio',{key:index,staticClass:"mb-2 mr-0 pr-4",style:({
                width: _vm.label.itemsPerLine
                  ? 100 / _vm.label.itemsPerLine + '%'
                  : 'auto',
              }),attrs:{"label":item[_vm.label.rows.nome],"value":item[_vm.label.rows.id],"readonly":_vm.editable === false || _vm.label.editable === false}})}),1):_vm._e(),(_vm.label.type == _vm.$fieldTypes.CHECKBOXES)?_c('v-radio-group',{staticClass:"mt-1",attrs:{"rules":_vm.rulesComputed,"row":!_vm.label.column,"value":_vm.checkboxesValue,"dense":"","readonly":_vm.editable === false || _vm.label.editable === false}},_vm._l((_vm.listItems),function(item,index){return _c('v-checkbox',{key:index,staticClass:"my-1 pr-4",style:({
                width: _vm.label.itemsPerLine
                  ? 100 / _vm.label.itemsPerLine + '%'
                  : 'auto',
              }),attrs:{"dense":"","label":item[_vm.label.rows.nome],"value":item[_vm.label.rows.id],"readonly":_vm.editable === false || _vm.label.editable === false,"hide-details":""},on:{"change":_vm.changeCheckValue},model:{value:(_vm.checkboxesValue),callback:function ($$v) {_vm.checkboxesValue=$$v},expression:"checkboxesValue"}})}),1):_vm._e(),(_vm.label.type == _vm.$fieldTypes.SWITCH)?_c('v-switch',{attrs:{"dense":"","outlined":"","readonly":_vm.editable === false || _vm.label.editable === false,"label":_vm.selectedItem || '',"input-value":_vm.value,"rules":_vm.rulesComputed,"hide-details":_vm.hideDetails},on:{"change":function($event){_vm.$emit('changed', $event);
              _vm.$emit('mutate', $event);
              _vm.$emit('input', $event);}}}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.FILE)?_c('uploader-v',{staticClass:"mb-4",attrs:{"multiple":_vm.label.multiple},on:{"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);
              _vm.onUpdateFiles($event);}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var selectFiles = ref.selectFiles;
return [_c('v-btn',{attrs:{"depressed":"","block":""},on:{"click":selectFiles}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v("Selecionar arquivo ")],1),_vm._l((_vm.value),function(anexo,akey){return _c('v-card',{key:akey,staticClass:"mt-2",attrs:{"elevation":"0","outlined":""}},[_c('v-progress-linear',{attrs:{"color":anexo.error ? 'error' : 'secondary',"value":anexo.percent,"striped":anexo.uploading,"height":"2em"}},[_c('v-card-text',{staticClass:"p-2"},[_c('v-row',{staticClass:"py-2 pl-2 pr-0",attrs:{"justify":"space-between","align":"center","no-gutters":""}},[_c('span',{staticClass:"v-label-input text-truncate",style:({width: anexo.uploading ? '82%' : '90%'})},[(anexo.error)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-document-alert")]):(anexo.uploading)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-document")]):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1 ml-n1",attrs:{"title":"Download","small":"","icon":"","href":anexo.url,"target":"_blank"}},[_c('v-icon',[_vm._v(_vm._s(hover ? 'mdi-download' : 'mdi-file-document'))])],1)]}}],null,true)}),_c('span',{attrs:{"title":("" + (anexo.nome) + (!anexo.error ? '' : ("\n" + (anexo.error))))}},[_vm._v(" "+_vm._s(anexo.error || anexo.nome)+" ")])],1),(anexo.uploading)?_c('span',{staticClass:"v-label-input"},[_vm._v(" "+_vm._s(anexo.percent)+"% ")]):_vm._e(),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.onDeleteFiles(akey)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close-circle-outline")])],1)],1)],1)],1)],1)})]}}],null,false,1641821963)}):_vm._e(),(_vm.label.type == _vm.$fieldTypes.AVATAR)?_c('div',[_c('v-file-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"file",attrs:{"accept":"image/png, image/jpeg, image/webp"},on:{"change":_vm.selectFoto}}),_c('v-avatar',{staticClass:"d-block mx-auto",attrs:{"size":"105"}},[_c('img',{attrs:{"src":_vm.value ? _vm.value : _vm.imgSrc,"alt":_vm.label.name}})]),_c('v-btn',{staticClass:"d-block mt-3 mx-auto",attrs:{"text":""},on:{"click":function($event){return _vm.$refs.file.$refs.input.click()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("$uploadfoto")]),_vm._v("Escolher foto ")],1)],1):_vm._e(),(_vm.label.type == _vm.$fieldTypes.TEXT_CHIP)?_c('input-chip',{class:{
              'input-chip': true,
              'center-input': _vm.label.align == 0,
              'right-input': _vm.label.align == 1,
            },attrs:{"readonly":_vm.editable === false || _vm.label.editable === false,"counter":_vm.label.counter,"rules":_vm.rulesComputed,"value":_vm.value,"placeholder":_vm.label.placeholder,"max-length":_vm.label.maxLength},on:{"blur":function($event){return _vm.$emit('blur', $event)},"change":function($event){return _vm.$emit('changed', $event)},"click":function($event){$event.stopPropagation();return _vm.nop()},"input":function($event){_vm.$emit('mutate', $event);
              _vm.$emit('input', $event);},"keypress":function($event){return _vm.$emit('keypress', $event)},"paste":function($event){return _vm.$emit('paste', $event)}}}):_vm._e()],1)],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }