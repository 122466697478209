<template>
  <v-col v-if="!hideInform" :cols="colSize" class="p-0 d-block-inline">
    <div v-if="label.type == $fieldTypes.SUBTITLE" class="pb-2">{{label.name}}</div>
    <v-form v-else ref="form" v-model="formValid" lazy-validation :class="{ 'my-1': hideDetails }" @submit.prevent>
      <v-sheet
        rounded
        :outlined="!exibirCampo"
        color="lighten-3"
        class="v-label-input pl-2 d-flex align-center"
        :class="{
          'mt-n1': !!label.ajuda || !exibirCampo,
          'pb-1': !!label.ajuda,
          'pb-2': !label.ajuda,
          'mb-5': !exibirCampo,
          'pt-2': !exibirCampo,
          'font-weight-medium': label.labelBold,
        }"
        :style="{ 'min-height': label.ajuda ? '23px' : '19px' }"
        v-if="
          !isNumber(label.type) &&
          label.type != $fieldTypes.AVATAR &&
          !hideDetails
        "
      >
        <span>{{ label.nameInForm || label.name }}</span>
        <v-btn v-if="label.ajuda" small icon @click="exibirAjuda = !exibirAjuda">
          <v-icon v-if="exibirAjuda" small>mdi-help-circle</v-icon>
          <v-icon v-else small>mdi-help-circle-outline</v-icon>
        </v-btn>
        <v-btn v-if="collapsible" small icon @click="exibirCampo = !exibirCampo">
          <v-icon v-if="exibirCampo" small>mdi-chevron-double-up</v-icon>
          <v-icon v-else small>mdi-chevron-double-down</v-icon>
        </v-btn>
        <v-btn
          class="ml-auto"
          v-if="!isUndefined(check)"
          small
          icon
          @click="
              canCheck ? $emit('update:check', check != 1 ? 1 : 0) : 0;
              canCheck ? $emit('changeCheck'): 0;
          "
        >
          <v-icon v-if="check === 1" color="#25935F">mdi-check-circle</v-icon>
          <v-icon v-else>mdi-check-circle-outline</v-icon>
        </v-btn>
      </v-sheet>
      <v-expand-transition>
        <div v-if="exibirAjuda">
          <div class="v-help-text mx-3 mb-2">{{ label.ajuda }}</div>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div v-show="exibirCampo">
          <v-sheet
            :outlined="label.outline"
            rounded
            :class="{ 'px-2': label.outline, 'mb-4': label.outline }"
          >
            <v-autocomplete
              v-if="label.type == $fieldTypes.AUTOCOMPLETE"
              :items="listItems"
              :item-value="label.rows.id"
              :item-text="label.rows.nome"
              :value="value"
              ref="autocompleteRef"
              :menu-props="{maxWidth: `${comboWidth}`}"
              dense
              outlined
              :readonly="editable === false || label.editable === false"
              :rules="rulesComputed"
              :clearable="canClearable && editable && label.editable !== false"
              auto-select-first
              :hide-details="hideDetails"
              :placeholder="label.placeholder"
              @change="$emit('changed', $event)"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
              "
            >

            <template v-slot:selection="{ item }">
                <v-chip color="disabled" v-if="label.multiple">
                  <span>{{ item[label.rows.nome] }}</span>
                </v-chip>
                <span v-else>{{ item[label.rows.nome] }}</span>
              </template>
              <template v-slot:item="{ item, attrs }">
                <v-checkbox :key="item[label.rows.id]" :input-value="attrs.inputValue" v-if="label.multiple"></v-checkbox>
                <v-tooltip right>
                  <template v-slot:activator="{on}">
                    <span v-on="on">{{ item[label.rows.nome]  }}</span>
                  </template>
                  <span :style="{
                      'text-overflow': item[label.rows.nome].length > 33 ? 'ellipsis' : 'unset'
                    }">
                    {{ item[label.rows.nome]  }}
                  </span>
                </v-tooltip>
              </template>
          </v-autocomplete>

            <v-autocomplete
              v-if="label.type == $fieldTypes.AUTOCOMPLETE_MULTIPLE"
              :items="listItems"
              :item-value="label.rows.id"
              :item-text="label.rows.nome"
              :value="value"
              ref="autocompleteRef"
              :menu-props="{maxWidth: `${comboWidth}`}"
              dense
              outlined
              :readonly="editable === false || label.editable === false"
              :rules="rulesComputed"
              :clearable="canClearable && editable && label.editable !== false"
              auto-select-first
              deletable-chips
              multiple
              small-chips
              :hide-details="hideDetails"
              :placeholder="label.placeholder"
              @change="$emit('changed', $event)"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
              "
            >
              <template v-slot:selection="{ item }">
                <v-chip color="disabled">
                  <span>{{ item[label.rows.nome] }}</span>
                </v-chip>
              </template>
              <template v-slot:item="{ item, attrs }">
                <v-checkbox :key="item[label.rows.id]" :input-value="attrs.inputValue"></v-checkbox>
                <v-tooltip right>
                  <template v-slot:activator="{on}">
                    <span v-on="on">{{ item[label.rows.nome]  }}</span>
                  </template>
                  <span :style="{
                      'text-overflow': item[label.rows.nome].length > 33 ? 'ellipsis' : 'unset'
                    }">
                    {{ item[label.rows.nome]  }}
                  </span>
                </v-tooltip>
              </template>
          </v-autocomplete>

            <v-text-field
              v-bind:class="{
                'center-input': label.align == 0,
                'right-input': label.align == 1,
              }"
              v-if="label.type == $fieldTypes.TEXT"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :counter="label.counter"
              :rules="rulesComputed"
              :value="value"
              :hide-details="hideDetails"
              :placeholder="label.placeholder"
              @click.stop="nop()"
              @blur="$emit('blur', $event)"
              @change="$emit('changed', $event)"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
              "
              @keypress="$emit('keypress', $event)"
              @paste="$emit('paste', $event)"
            ></v-text-field>

            <v-text-field
              v-bind:class="{
                'center-input': label.align == 0,
                'right-input': label.align == 1,
              }"
              v-if="label.type == $fieldTypes.DATETIME"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              :value="value | toDateTime"
              :hide-details="hideDetails"
              @click.stop="nop()"
              @change="$emit('changed', $event)"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
              "
            ></v-text-field>

            <v-textarea
              v-bind:class="{
                'center-input': label.align == 0,
                'right-input': label.align == 1,
              }"
              v-if="label.type == $fieldTypes.TEXTAREA"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              :value="value"
              :hide-details="hideDetails"
              :counter="label.counter"
              :placeholder="label.placeholder"
              @click.stop="nop()"
              @change="$emit('changed', $event)"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
              "
              @keypress="$emit('keypress', $event)"
              @paste="$emit('paste', $event)"
            ></v-textarea>

            <v-text-field
              v-if="label.type == $fieldTypes.PASSWORD"
              v-bind:class="{
                'center-input': label.align == 0,
                'right-input': label.align == 1,
              }"
              type="password"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              :value="value"
              :hide-details="hideDetails"
              @click.stop="nop()"
              @change="$emit('changed', $event)"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
              "
            ></v-text-field>

            <v-text-field
              v-if="label.type == $fieldTypes.NUMBER"
              type="number"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              :value="value"
              :hide-details="hideDetails"
              @click.stop="nop()"
              @change="$emit('changed', $event)"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
              "
            ></v-text-field>

            <v-text-field
              v-if="label.type == $fieldTypes.DOUBLE"
              type="number"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              :value="parseFloat(value | toDouble)"
              :hide-details="hideDetails"
              @click.stop="nop()"
              @change="$emit('changed', $event)"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
              "
            ></v-text-field>

            <v-text-field
              v-if="label.type == $fieldTypes.MONEY"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              v-money="money"
              v-model="moneyValue"
              :hide-details="hideDetails"
              @click.stop="nop()"
            ></v-text-field>

            <v-text-field
              v-if="label.type == $fieldTypes.MONEY2"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              v-money="money2"
              v-model="money2Value"
              :hide-details="hideDetails"
              @click.stop="nop()"
            ></v-text-field>

            <v-text-field
              v-if="label.type == $fieldTypes.CPF"
              v-bind:class="{
                'center-input': label.align == 0,
                'right-input': label.align == 1,
              }"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              type="tel"
              v-mask="cpfMask"
              :value="value"
              :hide-details="hideDetails"
              @click.stop="nop()"
              @change="
                $emit('mutate', $event);
                $emit('input', eventCpf($event));
              "
            ></v-text-field>

            <v-text-field
              v-if="label.type == $fieldTypes.CNPJ"
              v-bind:class="{
                'center-input': label.align == 0,
                'right-input': label.align == 1,
              }"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              type="tel"
              v-mask="cnpjMask"
              :value="value"
              :hide-details="hideDetails"
              @click.stop="nop()"
              @change="
                $emit('mutate', $event);
                $emit('input', eventCpf($event));
              "
            ></v-text-field>

            <v-menu
              v-model="commentMenu"
              :close-on-content-click="false"
              :nudge-width="250"
              offset-x
            >
              <template v-slot:activator="{  }">
                <div style="width:0px; padding:0px; margin:0px; height:0px;"></div>
              </template>
              <v-card>
                <div class="pt-2 mx-2">{{ label.commentForFieldLabel || "Comentário" }}</div>
                <v-textarea
                  class="pt-2 mx-2"
                  outlined
                  dense
                  persistent
                  :value="commentForField"
                  :hide-details="true"
                  @click.stop="nop()"
                  @change="$emit('changed', $event)"
                  @input="
                    $emit('update:commentForField', $event);
                    $emit('mutate', $event);
                  "
                ></v-textarea>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="commentMenu = false;">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>

            <v-text-field
              v-if="label.type == $fieldTypes.TIME"
              v-bind:class="{
                'center-input': label.align == 0,
                'right-input': label.align == 1,
              }"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              type="tel"
              v-mask="timeMask"
              :value="value | toTime"
              :hide-details="hideDetails"
              @click.stop="nop()"
              @change="
                $emit('mutate', $event);
                $emit('input', eventTime($event));
              "
            >
              <template v-if="value" v-slot:prepend-inner>
                <v-tooltip bottom :disabled="!commentForField">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs" v-on="on"
                      @click.stop="commentMenu = true"
                    >
                      {{ commentForField ? 'mdi-comment-text' : 'mdi-comment-outline' }}
                    </v-icon>
                  </template>
                  <span>{{ commentForField }}</span>
                </v-tooltip>
              </template>
            </v-text-field>

            <v-text-field
              v-if="label.type == $fieldTypes.BIGTIME"
              v-bind:class="{
                'center-input': label.align == 0,
                'right-input': label.align == 1,
              }"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              type="tel"
              v-mask="bigTimeMask"
              :value="value | toTime"
              :hide-details="hideDetails"
              @click.stop="nop()"
              @change="
                $emit('mutate', $event);
                $emit('input', eventTime($event));
              "
            ></v-text-field>

            <v-text-field
              v-if="label.type == $fieldTypes.TELEPHONE"
              v-bind:class="{
                'center-input': label.align == 0,
                'right-input': label.align == 1,
              }"
              :readonly="editable === false || label.editable === false"
              outlined
              dense
              :rules="rulesComputed"
              type="tel"
              v-mask="telephoneMask"
              :value="value"
              :hide-details="hideDetails"
              @click.stop="nop()"
              @change="
                $emit('mutate', $event);
                $emit('input', eventTelephone($event));
              "
            ></v-text-field>

            <v-menu
              v-if="label.type == $fieldTypes.DATE"
              v-model="dateMenu"
              :close-on-click="true"
              :close-on-content-click="false"
              transition="scroll-y-transition"
              offset-y
              right
              max-width="290px"
              min-width="290px"
              :disabled="editable === false || label.editable === false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind:class="{
                    'center-input': label.align == 0,
                    'right-input': label.align == 1,
                  }"
                  v-bind="attrs"
                  v-mask="dateMask"
                  :value="value | toDate"
                  :rules="rulesComputed"
                  :clearable="canClearable && editable && label.editable !== false"
                  :hide-details="hideDetails"
                  role="input"
                  outlined
                  dense
                  :append-icon="(editable === false || label.editable === false) ? null : 'mdi-calendar'"
                  :readonly="editable === false || label.editable === false"
                  @blur="dateFieldBlur($event)"
                  @click:append.stop="on.click"
                  @click:clear="
                    $emit('mutate', null);
                    $emit('input', null);
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                :value="value || defaultDatepickerValue"
                no-title
                @click:date="dateMenu = false;"
                @change="$emit('changed', $event)"
                @input="
                  $emit('mutate', $event);
                  $emit('input', $event);
                "
              ></v-date-picker>
            </v-menu>

            <v-menu
              v-if="label.type == $fieldTypes.MONTH"
              v-model="dateMenu"
              :close-on-click="true"
              :close-on-content-click="false"
              transition="scroll-y-transition"
              offset-y
              right
              max-width="290px"
              min-width="290px"
              :disabled="editable === false || label.editable === false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind:class="{
                    'center-input': label.align == 0,
                    'right-input': label.align == 1,
                  }"
                  :value="value | toMonth"
                  outlined
                  :rules="rulesComputed"
                  dense
                  :append-icon="(editable || label.editable) ? 'mdi-calendar' : null"
                  :readonly="!(editable || label.editable)"
                  v-bind="attrs"
                  @click:append.stop="
                    editable !== false &&
                    label.editable !== false &&
                    on.click($event)"
                  v-on="on"
                  @click:clear="
                    $emit('mutate', null);
                    $emit('input', null);
                  "
                  :clearable="canClearable && editable && label.editable !== false"
                  :hide-details="hideDetails"
                ></v-text-field>
              </template>
              <v-date-picker
                :value="value || defaultDatepickerValue"
                no-title
                type="month"
                @change="$emit('changed', $event)"
                @click:month="dateMenu = false;"
                @input="
                  $emit('mutate', $event);
                  $emit('input', $event);
                "
              ></v-date-picker>
            </v-menu>

            <v-select
              v-if="label.type == $fieldTypes.SELECT"
              :items="listItems"
              :item-value="label.rows.id"
              :item-text="label.rows.nome"
              :value="value"
              dense
              outlined
              :readonly="editable === false || label.editable === false"
              :rules="rulesComputed"
              :hide-details="hideDetails"
              :clearable="canClearable && editable && label.editable !== false"
              @change="$emit('changed', $event)"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
              "
            ></v-select>

            <v-combobox
              v-if="label.type == $fieldTypes.COMBOBOX"
              :items="listItems"
              :value="value"
              dense
              :menu-props="{maxWidth:  `${comboWidth}`}"
              ref="autocompleteRef"
              outlined
              :placeholder="label.placeholder"
              :readonly="editable === false || label.editable === false"
              :rules="rulesComputed"
              :hide-details="hideDetails"
              @change="$emit('changed', $event)"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
              "
            >

            <template v-slot:selection="{ item }">
                <v-chip color="disabled" v-if="label.multiple">
                  <span>{{ item }}</span>
                </v-chip>
                <span v-else>{{ item }}</span>
              </template>
              <template v-slot:item="{ item, attrs }">
                <v-checkbox :key="label.key" :input-value="attrs.inputValue" v-if="label.multiple"></v-checkbox>
                <v-tooltip right>
                  <template v-slot:activator="{on}">
                    <span v-on="on">{{ item }}</span>
                  </template>
                  <span :style="{
                      'text-overflow': item.length > 33 ? 'ellipsis' : 'unset'
                    }">
                    {{ item }}
                  </span>
                </v-tooltip>
              </template>
          </v-combobox>

            <v-radio-group
              :value="value"
              v-if="label.type == $fieldTypes.RADIO"
              :row="!label.column"
              dense
              @change="changeValue"
              :readonly="editable === false || label.editable === false"
            >
              <v-radio
                :style="{
                  width: label.itemsPerLine
                    ? 100 / label.itemsPerLine + '%'
                    : 'auto',
                }"
                class="mb-2 mr-0 pr-4"
                v-for="(item, index) in listItems"
                :label="item[label.rows.nome]"
                :value="item[label.rows.id]"
                :readonly="editable === false || label.editable === false"
                :key="index"
              ></v-radio>
            </v-radio-group>

            <v-radio-group
              class="mt-1"
              v-if="label.type == $fieldTypes.CHECKBOXES"
              :rules="rulesComputed"
              :row="!label.column"
              :value="checkboxesValue"
              dense
              :readonly="editable === false || label.editable === false"
            >
              <v-checkbox
                :style="{
                  width: label.itemsPerLine
                    ? 100 / label.itemsPerLine + '%'
                    : 'auto',
                }"
                dense
                v-for="(item, index) in listItems"
                :label="item[label.rows.nome]"
                :value="item[label.rows.id]"
                :key="index"
                v-model="checkboxesValue"
                @change="changeCheckValue"
                :readonly="editable === false || label.editable === false"
                hide-details
                class="my-1 pr-4"
              />
            </v-radio-group>

            <v-switch
              v-if="label.type == $fieldTypes.SWITCH"
              dense
              outlined
              :readonly="editable === false || label.editable === false"
              :label="selectedItem || ''"
              :input-value="value"
              :rules="rulesComputed"
              :hide-details="hideDetails"
              @change="
                $emit('changed', $event);
                $emit('mutate', $event);
                $emit('input', $event);
              "
            ></v-switch>

            <uploader-v
              v-if="label.type == $fieldTypes.FILE"
              v-slot="{ selectFiles }"
              :multiple="label.multiple"
              class="mb-4"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
                onUpdateFiles($event);
              "
            >
              <v-btn depressed block @click="selectFiles">
                <v-icon left>mdi-upload</v-icon>Selecionar arquivo
              </v-btn>
              <v-card
                v-for="(anexo, akey) in value"
                :key="akey"
                elevation="0"
                class="mt-2"
                outlined
              >
                <v-progress-linear
                  :color="anexo.error ? 'error' : 'secondary'"
                  :value="anexo.percent"
                  :striped="anexo.uploading"
                  height="2em"
                >
                  <v-card-text class="p-2">
                    <v-row justify="space-between" align="center" no-gutters class="py-2 pl-2 pr-0">
                      <span class="v-label-input text-truncate" :style="{width: anexo.uploading ? '82%' : '90%'}">
                        <v-icon v-if="anexo.error" left>mdi-file-document-alert</v-icon>
                        <v-icon v-else-if="anexo.uploading" left>mdi-file-document</v-icon>
                        <v-hover v-else v-slot="{ hover }">
                          <v-btn
                            title="Download"
                            class="mr-1 ml-n1"
                            small
                            icon
                            :href="anexo.url"
                            target="_blank"
                          >
                            <v-icon>{{ hover ? 'mdi-download' : 'mdi-file-document' }}</v-icon>
                          </v-btn>
                        </v-hover>
                        <span :title="`${anexo.nome}${!anexo.error ? '' : `\n${anexo.error}` }`"> {{ anexo.error || anexo.nome }} </span>
                      </span>
                      <span v-if="anexo.uploading" class="v-label-input"> {{ anexo.percent }}% </span>
                      <v-btn
                        small
                        icon
                        @click="onDeleteFiles(akey)"
                      >
                        <v-icon small>mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-progress-linear>
              </v-card>
            </uploader-v>

            <div v-if="label.type == $fieldTypes.AVATAR">
              <v-file-input
                ref="file"
                v-show="false"
                accept="image/png, image/jpeg, image/webp"
                @change="selectFoto"
              ></v-file-input>
              <v-avatar size="105" class="d-block mx-auto">
                <img :src="value ? value : imgSrc" :alt="label.name" />
              </v-avatar>
              <v-btn class="d-block mt-3 mx-auto" @click="$refs.file.$refs.input.click()" text>
                <v-icon left small>$uploadfoto</v-icon>Escolher foto
              </v-btn>
            </div>

            <input-chip
              v-if="label.type == $fieldTypes.TEXT_CHIP"
              :class="{
                'input-chip': true,
                'center-input': label.align == 0,
                'right-input': label.align == 1,
              }"
              :readonly="editable === false || label.editable === false"
              :counter="label.counter"
              :rules="rulesComputed"
              :value="value"
              :placeholder="label.placeholder"
              :max-length="label.maxLength"
              @blur="$emit('blur', $event)"
              @change="$emit('changed', $event)"
              @click.stop="nop()"
              @input="
                $emit('mutate', $event);
                $emit('input', $event);
              "
              @keypress="$emit('keypress', $event)"
              @paste="$emit('paste', $event)"
            ></input-chip>
          </v-sheet>
        </div>
      </v-expand-transition>
    </v-form>
  </v-col>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import { VMoney } from "v-money";
import { mask } from "vue-the-mask";
import { resizeImage } from "@/helpers/resizeImage";

export default {
  components: {
    "uploader-v": () => import("@/components/uploader-v.vue"),
    "input-chip": () => import("@/components/input-chip.vue"),
  },
  directives: { money: VMoney, mask: mask },
  props: {
    label: { default: false },
    value: {},
    editable: { default: true },
    opts: {},
    valid: { default: true },
    block: { default: false },
    check: { default: undefined },
    canCheck: { default: false },
    hideDetails: { default: false },
    collapsible: { default: false },
    validate: { default: false },
    colSize: { default: 12 },
    hideInform: { default: false },
    noClearable: { type: Boolean, default: false },
    commentForField: { default: undefined },
    form: {},
  },
  data: function () {
    return {
      exibirAjuda: false,
      exibirCampo: true,
      moneyValue: "",
      money2Value: "",
      checkboxesValue: [],
      formValid: null,
      imgSrc: this.$userDefaultImgBase64,
      imgBlob: null,
      dateMenu: false,
      commentMenu: false,
      listValues: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
      },
      money2: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        precision: 2,
      },
      cpfMask: {
        mask: "###.###.###-##",
        tokens: {
          "#": { pattern: /\d/ },
        },
      },
      cnpjMask: {
        mask: "##.###.###/####-##",
        tokens: {
          "#": { pattern: /\d/ },
        },
      },
      timeMask: {
        mask: ["H#:M#", "#:M#"],
        tokens: {
          H: { pattern: /[0-2]/ },
          M: { pattern: /[0-6]/ },
          "#": { pattern: /\d/ },
        },
      },
      bigTimeMask: {
        mask: ["###:##", "##:##", "#:##"],
        tokens: {
          M: { pattern: /[0-6]/ },
          "#": { pattern: /\d/ },
        },
      },
      telephoneMask: {
        mask: ["(##) #####-####", "(##) ####-####"],
        tokens: {
          "#": { pattern: /\d/ },
        },
      },
      dateMask: {
        mask: '##/##/####',
        tokens: {
          "#": { pattern: /\d/ },
        },
      },
      rulesData: {
        required: (value) => {
          var a =
            (typeof value !== "undefined" &&
              value !== "" &&
              value !== null &&
              !(Array.isArray(value) && value.length === 0)) ||
            "Campo Obrigatório.";
          return a;
        },
        requiredIf: (value, params) => {
          var a =
            (typeof value !== "undefined" &&
              value !== "" &&
              value !== null &&
              value?.length > 0) ||
            !params.condition() ||
            "Campo Obrigatório.";
          return a;
        },
        min: (value, params) => {
          var a =
            !value ||
            (!!value && value.length >= params.size) ||
            `Mínimo de ${params.size} caracteres.`;
          return a;
        },
        max: (value, params) => {
          var a =
            !value ||
            (!!value && value.length <= params.size) ||
            `Máximo de ${params.size} caracteres.`;
          return a;
        },
        equal: (value, params) => {
          var a =
            value === params.value() ||
            `Deve ser igual ao campo ${params.name}.`;
          return a;
        },
        projectOnDate: (value, params) => {
          var a = params.test(value) || 'Projeto já finalizado.';
          return a;
        },
        email: (value) => {
          const re = /\S+@\S+\.\S+/;
          return !value || re.test(value) || 'Digite um e-mail válido.';
        },
        between: (value, params) => {
          return !value || (params.min <= value && value <= params.max) || `Deve ser um valor entre ${params.min} e ${params.max}.`;
        },
      },
      comboWidth: 428,
    };
  },
  watch: {
    label: function () {
      this.setValidate();
      this.setMaxLength();
    },
    moneyValue: function (newVal, oldValue) {
      if (this.label.type == this.$fieldTypes.MONEY && newVal != oldValue) {
        this.$emit("mutate", newVal);
        this.$emit("input", this.eventMoney(newVal));
      }
    },
    money2Value: function (newVal, oldValue) {
      if (this.label.type == this.$fieldTypes.MONEY2 && newVal != oldValue) {
        this.$emit("mutate", newVal);
        this.$emit("input", this.eventMoney(newVal));
      }
    },
    value: function (nextValue) {
      if (this.label.type == this.$fieldTypes.CHECKBOXES) {
        this.checkboxesValue = Array.isArray(nextValue) ? nextValue : nextValue ? [nextValue] : [];
      } else if (this.label.type == this.$fieldTypes.MONEY) {
        this.moneyValue = Vue.filter("toCurrency")(nextValue * 1);
      }
    },
  },
  created: function () {
    if (!this.value && this.label.defaultValue) {
      this.$emit("input", this.label.defaultValue);
    }

    if (this.label.type == this.$fieldTypes.MONEY) {
      this.moneyValue = Vue.filter("toCurrency")(this.value * 1);
    }

    if (this.label.type == this.$fieldTypes.MONEY2) {
      this.money2Value = Vue.filter("toCurrencyValue")(this.value * 1);
    }

    if (
      !_.isEqual(this.value, this.checkboxesValue) &&
      this.label.type == this.$fieldTypes.CHECKBOXES
    ) {
      this.checkboxesValue = this.value && this.value != null ? this.value : [];
    }

    this.label.rules = this.label.rules || [];

    this.setValidate();
  },
  mounted() {
    this.setMaxLength();
  },
  methods: {
    onUpdateFiles: function (value) {
      const shouldBlock = !value || value
        .some((file) => file.uploading || [undefined, null, '']
        .includes(file.url));
      this.$emit("update:files", value)
      this.$emit("update:block", shouldBlock);
    },
    onDeleteFiles: function (index) {
        this.value.splice(index, 1);
        this.$emit('mutate', this.value);
        this.$emit('input', this.value);
        this.onUpdateFiles(this.value);
    },
    changeCheckValue: function () {
      if (!_.isEqual(this.value, this.checkboxesValue)) {
        this.$emit("mutate", this.checkboxesValue);
        this.$emit("input", this.checkboxesValue);
      }
    },
    changeValue: function (event) {
      this.$emit("mutate", event);
      this.$emit("input", event);
    },
    setMaxLength: function () {
      const input = this.$refs.form.$el.querySelector('input');
      const textarea = this.$refs.form.$el.querySelector('textarea');

      if (this.label.maxLength) {
        input && (input.maxLength = this.label.maxLength);
        textarea && (textarea.maxLength = this.label.maxLength);
      }
    },
    setValidate: function () {
      this.$emit("update:valid", () => {
        return this.$refs && this.$refs.form
          ? this.$refs.form.validate()
          : true;
      });
    },
    selectFoto(file) {
      resizeImage(file, 200, 200).then(({ blob, dataURI }) => {
        this.imgSrc = dataURI;
        this.blob = blob;

        this.koreUpload(
          "/v1/uploads",
          new File([blob], file.name),
          (completed, i) => {
            if (completed === true) {
              this.$emit("mutate", i.url);
              this.$emit("input", i.url);
            }
          }
        );
      });
    },
    nop: function () {},
    isUndefined: function (val) {
      return typeof val === "undefined";
    },
    isNumber: function (val) {
      return typeof val === "number";
    },
    eventMoney: function (e) {
      var transform =
        ((e + "").replace(/[^0-9,-]+/g, "").replace(",", "") * 1) / 100;
      return transform;
    },
    eventTime: function (e) {
      var hm = e.split(":");
      var v = hm[0] * 1;
      if (hm[1] && hm[1] != "") {
        hm[1] = hm[1].length == 1 ? hm[1] * 10 : hm[1];
        v += hm[1] / 60;
      }
      return v;
    },
    eventCpf: function (e) {
      const onlyNumbers = e.replaceAll(/[^0-9]/g, '');
      return onlyNumbers;
    },
    eventTelephone: function (e) {
      const onlyNumbers = e.replaceAll(/[^0-9]/g, '');
      return parseInt(onlyNumbers, 10);
    },
    isValidCPF: function (cpf) {
      if (typeof cpf !== "string") return false;
      cpf = cpf.replace(/[\s.-]*/gim, "");
      if (
        cpf.length !== 11 ||
        !Array.from(cpf).filter((e) => e !== cpf[0]).length
      ) {
        return false;
      }
      var soma = 0;
      var resto;
      for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;
      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(cpf.substring(9, 10))) return false;
      soma = 0;
      for (var j = 1; j <= 10; j++)
        soma = soma + parseInt(cpf.substring(j - 1, j)) * (12 - j);
      resto = (soma * 10) % 11;
      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(cpf.substring(10, 11))) return false;
      return true;
    },
    isValidCNPJ: function (value) {
      if (!value) return false;

      // Aceita receber o valor como string, número ou array com todos os dígitos
      const validTypes =
        typeof value === "string" ||
        Number.isInteger(value) ||
        Array.isArray(value);

      // Elimina valor em formato inválido
      if (!validTypes) return false;

      // Guarda um array com todos os dígitos do valor
      const match = value.toString().match(/\d/g);
      const numbers = Array.isArray(match) ? match.map(Number) : [];

      // Valida a quantidade de dígitos
      if (numbers.length !== 14) return false;

      // Elimina inválidos com todos os dígitos iguais
      const items = [...new Set(numbers)];
      if (items.length === 1) return false;

      // Cálculo validador
      const calc = (x) => {
        const slice = numbers.slice(0, x);
        let factor = x - 7;
        let sum = 0;

        for (let i = x; i >= 1; i--) {
          const n = slice[x - i];
          sum += n * factor--;
          if (factor < 2) factor = 9;
        }

        const result = 11 - (sum % 11);

        return result > 9 ? 0 : result;
      };

      // Separa os 2 últimos dígitos de verificadores
      const digits = numbers.slice(12);

      // Valida 1o. dígito verificador
      const digit0 = calc(12);
      if (digit0 !== digits[0]) return false;

      // Valida 2o. dígito verificador
      const digit1 = calc(13);
      return digit1 === digits[1];
    },
    dateFieldBlur: function ({ target: { value } }) {
      const date = moment(value, 'DD/MM/YYYY', true);

      if (date.isValid()) {
        const iso = date.format('YYYY-MM-DD');

        if (iso !== this.value) {
          this.$emit('input', iso);
          this.$emit('mutate', iso);
        }
      }
    },
  },
  computed: {
    rulesComputed: function () {
      if (this.editable === false || this.label.editable === false) {
        return [];
      }

      let rules = (this.label.rules || []).map((r) => {
        if (typeof r === 'function') {
          return r;
        }

        let validator = this.rulesData[r.rule];
        return (value) => validator(value, r.params);
      });

      if (this.label.type == this.$fieldTypes.CPF) {
        rules.push(
          (value) =>
            !value || (!!value && this.isValidCPF(value)) || "CPF inválido."
        );
      }

      if (this.label.type == this.$fieldTypes.CNPJ) {
        rules.push(
          (value) =>
            !value || (!!value && this.isValidCNPJ(value)) || "CNPJ inválido."
        );
      }

      if (this.label.type == this.$fieldTypes.DATE) {
        rules.push(
          (value) =>
            !value || moment(value, 'DD/MM/YYYY', true).isValid() || "Data inválida."
        );
      }

      return rules;
    },
    listItems: function () {
      const label = this.label;
      if (this.isUndefined(label.rows) && !this.isUndefined(label.rel)) {
        label.rows = {
          id: label.rel.key,
          nome: label.rel.name,
          data: label.rel.toEdit || label.rel.to,
          nameSpacer: label.rel.nameSpacer,
        };
      }
      if (label.rows.data === false) {
        return [];
      }
      var d = label.rows.data;
      if (typeof d == "string") {
        d = this.opts[d];
      }
      if (Array.isArray(label.rows.nome)) {
        d = d.map((item) => {
          item[label.rows.nome.join("")] = label.rows.nome
            .map((field) => item[field])
            .join(label.rows.nameSpacer);
          return item;
        });
        label.rows.nome = label.rows.nome.join("");
      }
      return d;
    },
    canClearable: function () {
      return !this.noClearable && !this.label?.noClearable;
    },
    defaultDatepickerValue: function () {
      const filteredYear = this.getFilters('anoBase') || moment().format('YYY');
      const date = moment().year(filteredYear);
      return date.format('YYYY-MM-DD');
    },
    selectedItem: function() {
      const { key, name } = this.label?.rel || {};

      if (key && name) {
        const item = this.listItems.find((item) => item[key] == this.value);

        if (item) {
          return item[name];
        }
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.center-input >>> input {
  text-align: center;
}

.right-input >>> input {
  text-align: end;
}

.v-progress-linear__content {
  .v-btn > .v-btn__content .v-icon,
  .v-label-input {
    &,
    & .theme--light.v-icon {
      color: rgba(255, 255, 255, 0.87);
    }
  }
}

.input-chip.input-chip {
  width: unset;
}
</style>
